import { H1 } from '@repo/ui/components/Typography.js'
import { cn } from '@repo/ui/lib/utils'
import type { ComponentProps } from 'react'

export const PageTitle = ({ ...props }: ComponentProps<'h1'>) => {
  return <H1 {...props} className={cn('text-xl', props.className)} />
}

export const PageSubtitle = ({
  className,
  ...props
}: ComponentProps<'div'>) => {
  return <div className={cn('text-muted-foreground', className)} {...props} />
}
